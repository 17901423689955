import _isNil from 'lodash/isNil';
import { fromJS } from 'immutable';
import 'prop-types';
import { validateImmutableMap } from '@dbh/validation';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { getAsJsonStringToStoreAsCookieValue } from '@dbh/cookie-utils';
import 'invariant';
import { isServerSideRendering } from '@dbh/environment';
import { memoize } from '@dbh/lodash-extra';
import '@dbh/throw-in-server-side-rendering';
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import '@dbh/cookie-keys';
import _noop from 'lodash/noop';
import '@dbh/date-fns-prop-types';

/**
 * Get the value of a cookie.
 * @param {Object} cookies From `useCookies` (cookie values must be accessed
 * directly).
 * @param {string} COOKIE_KEY The key of the cookie. @see `@dbh/app/src/constants`.
 * @param {Function?} fnFallback Returns a fallback `Immutable.Map` value.
 * @return {Immutable.Map?} The value of the cookie.
 * @see {@link https://bit.ly/2NTLTye}
 */const getImmutableCookie=withConformsTo("getImmutableCookie",[],(a,b,c)=>{const d=!(a.get&&"function"==typeof a.get);let e=d?a[b]:a.get(b);const f=_isNil(e);return f?c?(e=c(),validateImmutableMap(e,"value","getImmutableCookie"),e):void 0:fromJS(e);// We use `fromJS` instead of `Map` because a deep conversion may be needed,
// we can't know.
});var getImmutableCookie$1 = getImmutableCookie;

/*
 *
 * Constants: `@dbh/cookies`.
 *
 */ // @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#Creating_cookies}
// @see {@link https://github.com/reactivestack/cookies/blob/master/packages/react-cookie/README.md#setname-value-options}
const COOKIE_OPTIONS_DEFAULT=Object.freeze({// Cookie is available to all the sub-paths of the current host.
path:"/"});

let cookiesPropTypeBase=_noop;const cookiesPropType=cookiesPropTypeBase;/**
 * @typedef {Object} CookieOptions
 * @property {string?} path .
 * @property {Date?} expires .
 * @property {number?} maxAge .
 * @property {string?} domain .
 * @property {boolean?} secure .
 * @property {boolean?} httpOnly .
 * @property {boolean?} sameSite .
 * @property {boolean?} partitioned .
 * @see {@link https://www.npmjs.com/package/universal-cookie?activeTab=readme#setname-value-options}
 */const cookieOptionsPropType=_noop;

/**
 * Sets the value of the cookie with the given name (key).
 * @param {Object} cookies .
 * @param {string} COOKIE_KEY The key of the cookie. @see `@dbh/cookie-keys`.
 * @param {Immutable.Map} value A value that can be serialized.
 * @param {Object?} options The options from `RFC 6265`.
 * @see {@link https://bit.ly/2y7y3x9}
 */const setImmutableCookie=withConformsTo("setImmutableCookie",[],(a,b,c,d)=>{a.set(b,getAsJsonStringToStoreAsCookieValue(c),{...COOKIE_OPTIONS_DEFAULT,...d});});var setImmutableCookie$1 = setImmutableCookie;

/**
 * @typedef {import('./types').CookieOptions} CookieOptions
 */const removeCookie=withConformsTo("removeCookie",[],(a,b,c)=>{const d={...COOKIE_OPTIONS_DEFAULT,...c};a.remove(b,d);});/**
 * Removes the cookie with the given name (key).
 * @param {Object} cookies .
 * @param {string} COOKIE_KEY Key of the cookie. @see `@dbh/cookie-keys`.
 * @param {CookieOptions?} options .
 * @see {@link https://bit.ly/2NWylBK}
 */var removeCookie$1 = removeCookie;

/**
 * Detects if cookies are enabled in the environment this function is called in,
 * by trying to create a cookie and then retrieve it. Always returns `true` in
 * server side rendering. This is SLOW in the browser, as it creates a new
 * cookie every time it is executed: be careful.
 * @return {boolean} Are the cookies enabled.
 */const areCookiesEnabledSlow=()=>{// We use `universal-cookie` to pass cookies with the `html` page.
// @see {@link https://www.npmjs.com/package/universal-cookie}
// @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie}
if(isServerSideRendering())return !0;// Sadly `window.navigator.cookieEnabled` is unreliable in `IE11`: if you turn
// off cookies, `cookieEnabled` returns true and doesn't store them.
// We have to rely on feature detection as long as we support `IE11`.
// The remaining code is largely based on `Modernizr` cookie rule.
// @see {@link https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js}
try{const a="dbh-cookie-test-"+Math.random();// Accessing `document.cookie` can throw an exception.
document.cookie=a+"=1";const b=-1!==document.cookie.indexOf(a+"=");return b&&(document.cookie=a+"=1; expires=Thu, 01-Jan-1970 00:00:01 GMT"),b}catch(a){return !1}},areCookiesEnabledInTheBrowser=memoize(()=>(areCookiesEnabledSlow()));/**
 * This function is `memoized`, so if the user switched the cookies on from off,
 * or off from on during the navigation, the result wouldn't change: it just
 * checks once. To be executed only in the browser, as the name says.
 * @return {boolean} @see `areCookiesEnabledSlow`.
 */var areCookiesEnabledInTheBrowser$1 = areCookiesEnabledInTheBrowser;

/**
 * Returns two callbacks, one to set a value into the cookie, the other to remove
 * the cookie.
 * @param {string} cookieKey .
 * @param {Object?} setCookieOptions .
 * @return {Function} .
 */const useSetAndRemoveCookie=withConformsTo("useSetAndRemoveCookie",[],(a,b)=>{const[,c,d]=useCookies(),e=useCallback(withConformsTo("useSetAndRemoveCookie#callback",[],d=>c(a,getAsJsonStringToStoreAsCookieValue(d),{...COOKIE_OPTIONS_DEFAULT,...b})),[c,a]),f=useCallback(withConformsTo("useSetAndRemoveCookie#callback",[],b=>{d(a,b),d(a,{path:"/"});}),[d,a]);// eslint-disable-next-line react-hooks/exhaustive-deps
// eslint-disable-next-line react-hooks/exhaustive-deps
return [e,f]});var useSetAndRemoveCookie$1 = useSetAndRemoveCookie;

export { COOKIE_OPTIONS_DEFAULT, areCookiesEnabledInTheBrowser$1 as areCookiesEnabledInTheBrowser, cookieOptionsPropType, cookiesPropType, getImmutableCookie$1 as getImmutableCookie, removeCookie$1 as removeCookie, setImmutableCookie$1 as setImmutableCookie, useSetAndRemoveCookie$1 as useSetAndRemoveCookie };
