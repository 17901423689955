import { Map } from 'immutable';
import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { createSelector } from 'reselect';
import { makeSelectCountry, makeSelectLocale } from '@dbh/routing-redux';
import { createSimpleSelector, useMakeSelect } from '@dbh/reselect-extra';

// is always injected in server side rendering. @see `@dbh/create-reducer-redux`.
const REDUX_AND_SAGA_KEY="dateFnsLocales";const FNS_LOCALE_DATA_LOADED="@dbh/date-fns-locales-redux/FNS_LOCALE_DATA_LOADED";const initialState=Map({dateFnsLocales:Map()});

const dateFnsLocalesReducer=function(a,b){switch(void 0===a&&(a=initialState),b.type){case FNS_LOCALE_DATA_LOADED:{const{payload:{dateFnsLocale:c,locale:d,country:e}}=b;return a.setIn(["dateFnsLocales",...[e,d]],c)}default:return a}};var dateFnsLocalesReducer$1 = dateFnsLocalesReducer;

/**
 * @typedef {import('date-fns').Locale} Locale
 */ /**
 * Returns the `date-fns` locale from the given app locales.
 * @param {string} locale @example `en-GB`, `it-IT`.
 * @return {Promise<Locale?>} .
 */const getDateFnsLocaleData=withConformsTo("getDateFnsLocaleData",[],async a=>{let b;// @see `getBabelConfig.js`, `webpack.browser.base.js`
try{const{default:c}=await import(/* webpackChunkName: "date-fns" */"date-fns/locale/"+a+"/index.js");b=c;}catch(c){const[d]=a.split("-"),{default:e}=await import(/* webpackChunkName: "date-fns" */"date-fns/locale/"+d+"/index.js");// Fallback in case our locale is not supported by `date-fns`.
b=e;}return b});var getDateFnsLocaleData$1 = getDateFnsLocaleData;

/**
 * Returns the slice of the state that contains the `dateFnsLocales` state.
 * @param {Immutable.Map} state `redux` State.
 * @return {Immutable.Map?} .
 */const selectDateFnsLocales=a=>a.get(REDUX_AND_SAGA_KEY);var selectDateFnsLocales$1 = selectDateFnsLocales;

/**
 * Returns a selector that returns all the `date-fns` locales data.
 * @return {Immutable.Map} The `date-fns` locale data.
 */const makeSelectAllDateFnsLocales=()=>createSimpleSelector(selectDateFnsLocales$1,["dateFnsLocales"]);var makeSelectAllDateFnsLocales$1 = makeSelectAllDateFnsLocales;

/**
 * @typedef {import('date-fns').Locale} Locale
 */ /**
 * Returns a selector that returns the current `date-fns` locale data.
 * @return {Locale?} .
 */const makeSelectCurrentDateFnsLocaleData=()=>createSelector(makeSelectCountry(),makeSelectLocale(),makeSelectAllDateFnsLocales$1(),(a,b,c)=>{const d=c.getIn([a,b]);return d});var makeSelectCurrentDateFnsLocaleData$1 = makeSelectCurrentDateFnsLocaleData;

/**
 * @typedef {import('date-fns').Locale} Locale
 */ /**
 * Returns the current `date-fns` locale data.
 * @return {Locale?} .
 */const useCurrentDateFnsLocaleData=()=>{const a=useMakeSelect(makeSelectCurrentDateFnsLocaleData$1);return a};var useCurrentDateFnsLocaleData$1 = useCurrentDateFnsLocaleData;

/**
 * @typedef {import('date-fns').Locale} Locale
 */ /**
 * Dispatched when the `date-fns` locale is lazy loaded.
 * @param {Object} options .
 * @param {string} options.country .
 * @param {string} options.locale .
 * @param {Locale} options.dateFnsLocale .
 * @return {Object} .
 */const fnsLocaleDataLoaded=a=>{let{country:b,locale:c,dateFnsLocale:d}=a;return {type:FNS_LOCALE_DATA_LOADED,payload:{country:b,locale:c,dateFnsLocale:d}}};

const importDateFnsLocalesSagas=import('./index-01684e38.js'/* webpackChunkName: 'dbh.date-fns-locales-sagas' */);

export { FNS_LOCALE_DATA_LOADED, REDUX_AND_SAGA_KEY, dateFnsLocalesReducer$1 as dateFnsLocalesReducer, fnsLocaleDataLoaded, getDateFnsLocaleData$1 as getDateFnsLocaleData, importDateFnsLocalesSagas, initialState, makeSelectCurrentDateFnsLocaleData$1 as makeSelectCurrentDateFnsLocaleData, useCurrentDateFnsLocaleData$1 as useCurrentDateFnsLocaleData };
