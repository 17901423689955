import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import 'prop-types';
import '@dbh/date-fns-prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import format from 'date-fns/format';
import { enGB } from 'date-fns/locale';
import '@dbh/base-react-moment-proptypes';
import { memoize } from '@dbh/lodash-extra';

/**
 * Returns `true` if the given date string matches the expected date format and
 * is valid.
 * @see {@link https://stackoverflow.com/questions/66068149/receive-a-string-and-verify-if-it-is-a-valid-date-with-date-fns}
 * @param {string} dateString @example `2031-12-15`.
 * @param {string} dateFormat @example `yyyy-MM-dd`.
 * @return {boolean} .
 */const isValidDateString=withConformsTo("isValidDateString",[],(a,b)=>{const c=parse(a,b,new Date);return isValid(c)});var isValidDateString$1 = isValidDateString;

/*
 *
 * `getDateUTC`: `@dbh/dates`.
 *
 */ /**
 * A function that returns a Date object that represents the current instant in
 * `UTC` date.
 * @see {@link https://stackoverflow.com/a/6777470/7457356}
 * @return {Date} The current instant in `UTC` mode.
 */const getDateUTC=()=>{const a=new Date,b=Date.UTC(a.getUTCFullYear(),a.getUTCMonth(),a.getUTCDate(),a.getUTCHours(),a.getUTCMinutes(),a.getUTCSeconds());return new Date(b)};var getDateUTC$1 = getDateUTC;

/*
 *
 * Constants: `@dbh/dates`.
 *
 */const DEFAULT_DATE_ID_FORMAT="yyyy-MM-dd";const DEFAULT_DATE_LABEL_FORMAT="P";

/**
 * @typedef {import('date-fns').Locale} Locale
 */ /**
 * Valid date representation.
 * In case of a string type, it should be a parsable date.
 * @typedef {Moment|string|Date} ValidDate
 */ /**
 * Renders a localized date with the optional provided format.
 * @param {ValidDate} date .
 * @param {string?} dateformat Can be passed to `date-fns` format.
 * Defaults to `DEFAULT_DATE_LABEL_FORMAT` if falsy.
 * @param {Locale?} dateFnsLocaleData Defaults to `enGB` if falsy.
 * @param {boolean} adjustForTimezoneOffset Adjusts the date to account for
 * timezones where the day already changed or still has to change. This should
 * be set to `true` in all cases where we are formatting a date that does not
 * include an hour of the day (for example a date picker, a booking date, etc).
 * @return {string} The formatted date that takes into account the current
 * locale.
 * @throws {Error} If provided input arguments do not match the expected types.
 * @example
 * 1. Get formatted date with current locale:
 * ```javascript
 *  // Locale set to `it-IT`.
 *  formatDate(date, 'dddd yyyy') // Outputs 'giovedì - 1970'
 * ```
 * 2. Get formatted date with a specific locale.
 * ```javascript
 * // Locale set to `it-IT`.
 *  formatDate(date, 'dddd yyyy', 'en-GB') // Outputs 'Thursday - 1970'
 * ```
 */const formatDate=withConformsTo("formatDate",[],(a,b,c,d)=>{// @TODO: consider passing the `date` and a second `options` object parameter.
// To make sure you don't cause more renders in the application, log the
// renders in the main components and selectors that call this, in the
// browser, on a landing page (like the home), and a page transition (like
// from home to search results page).
let e;if(e="string"==typeof a?new Date(a):a.toDate?a.toDate():a,d){const a=e.getHours(),b=e.getMinutes();// In some cases the date might've already been adjusted for. Only execute
// the following code if the hours or the minutes are not zero.
if(a||b){const a=e.getTimezoneOffset(),b=e.getTime()+1e3*(60*a);e=new Date(b);}}return format(e,b||DEFAULT_DATE_LABEL_FORMAT,{locale:c||enGB})});var formatDate$1 = formatDate;

/**
 * Given a date object returns a formatted date string, using `en-GB` locale.
 * @param {Date} date .
 * @return {string} The date formatted.
 */const getDateId=withConformsTo("getDateId",[],a=>formatDate$1(a,DEFAULT_DATE_ID_FORMAT,enGB,!0));var getDateId$1 = getDateId;

/**
 * Given a date, returns a formatted string of the date that it holds.
 * @param {(Date|Moment)?} date .
 * @return {string?} A formatted date.
 * @throws {Error} If date is passed and is not in a valid format.
 */const dateObjectToDefaultDateString=withConformsTo("dateObjectToDefaultDateString",[],a=>a?formatDate$1(a,DEFAULT_DATE_ID_FORMAT,enGB,!0):void 0);var dateObjectToDefaultDateString$1 = dateObjectToDefaultDateString;

/**
 * @typedef {import('date-fns').Locale} Locale
 */ /**
 * Returns the input time string in the localized format: `HH:MM`.
 * @param {string} timeOfDay @example `15:30`.
 * @param {Object} options .
 * @param {string?} options.format Custom formatter, uses `p` if falsy.
 * @param {Locale?} options.dateFnsLocale .
 * @return {string} Input formatted in local time.
 * @see {@link https://date-fns.org/v2.25.0/docs/format}
 */const formatTimeOfDay=withConformsTo("formatTimeOfDay",[],a=>{const{timeOfDay:b,format:c,dateFnsLocale:d}=a,[e,f]=b.split(":"),g=new Date;// Locale time is hours and minutes without seconds.
return g.setHours(e,f),formatDate$1(g,c||"p",d)});var formatTimeOfDay$1 = formatTimeOfDay;

/**
 * @typedef {import('date-fns').Locale} Locale
 */ /**
 * Returns `true` if the current `date-fns` locale uses twelve hour format.
 * @example `00:00` -> `12:00 AM`.
 * @param {Locale?} currentDateFnsLocale .
 * @return {boolean} .
 * @see {@link https://date-fns.org/v2.25.0/docs/format}
 */const doesCurrentLocaleUseTwelveHourFormat=memoize(a=>/(?:a|p)m$/i.test(formatDate$1(new Date,"p",a)));var doesCurrentLocaleUseTwelveHourFormat$1 = doesCurrentLocaleUseTwelveHourFormat;

export { DEFAULT_DATE_ID_FORMAT, DEFAULT_DATE_LABEL_FORMAT, dateObjectToDefaultDateString$1 as dateObjectToDefaultDateString, doesCurrentLocaleUseTwelveHourFormat$1 as doesCurrentLocaleUseTwelveHourFormat, formatDate$1 as formatDate, formatTimeOfDay$1 as formatTimeOfDay, getDateId$1 as getDateId, getDateUTC$1 as getDateUTC, isValidDateString$1 as isValidDateString };
